import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import SettingsCustom from "../pages/SettingsCustom/";
import Financeiro from "../pages/Financeiro/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import Queues from "../pages/Queues/";
import Tags from "../pages/Tags/";
import MessagesAPI from "../pages/MessagesAPI/";
import Helps from "../pages/Helps/";
import ContactLists from "../pages/ContactLists/";
import ContactListItems from "../pages/ContactListItems/";
// import Companies from "../pages/Companies/";
import QuickMessages from "../pages/QuickMessages/";
import Kanban from "../pages/Kanban";
import { AuthContext, AuthProvider } from "../context/Auth/AuthContext";
import { TicketsContextProvider } from "../context/Tickets/TicketsContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import Schedules from "../pages/Schedules";
import Campaigns from "../pages/Campaigns";
import CampaignsConfig from "../pages/CampaignsConfig";
import CampaignReport from "../pages/CampaignReport";
import Annoucements from "../pages/Annoucements";
import Chat from "../pages/Chat";
import ToDoList from "../pages/ToDoList/";
import Subscription from "../pages/Subscription/";
import Files from "../pages/Files/";
import Prompts from "../pages/Prompts";
import QueueIntegration from "../pages/QueueIntegration";
import Email from "../pages/Email/";  //Email
import EmailLis from "../pages/EmailLis/"; //Email
//import EmailScheduler from "../pages/EmailScheduler/"; //Email
//import EmailsAgendado from "../pages/EmailsAgendado/"; //Email
import ForgetPassword from "../pages/ForgetPassWord/"; // Reset PassWd
import Departments from "../pages/Departments";
import DepartmentDetails from "../components/DepartmentDetails";
import DepartmentReport from "../components/DepartmentReport";
import NewDashboard from "../pages/Dashboard/NewDashboard";
import FolderView from "../components/FolderView";
import DepartmentSettings from "../pages/DepartmentSettings";
import { LoginNew } from "../pages/LoginNew";
import DepartamentsUsers from "../pages/DepartamentsUsers";
import { Home } from "../pages/home";
import { AscviewHome } from "../pages/AscviewHome";
import usePlans from "../hooks/usePlans";

const Routes = () => {
  const [showCampaigns, setShowCampaigns] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const cshow = localStorage.getItem("cshow");
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }
  }, []);

  function ProtectedRoutesAscChat({ component: Component, redirectTo, ...rest }) {
    const { getPlanCompany } = usePlans();
    const [hasPermission, setHasPermission] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      async function verifyIfHasAscChatPermission() {
        const companyId = user.companyId;

        const planConfigs = await getPlanCompany(undefined, companyId); 

        if(!planConfigs || !planConfigs.plan) return;

        setHasPermission(planConfigs.plan.useAscChat);
        
        // setIsLoading(false);
      }
      verifyIfHasAscChatPermission();
    }, [getPlanCompany]);
  
    // if (isLoading) {
    //   return <div>Loading...</div>; // Exiba um carregando enquanto espera a verificação
    // }
  
    return (
      <Route
        {...rest}
        render={(props) =>
          hasPermission ? <Component {...props} /> : <Redirect to={redirectTo} />
        }
      />
    );
  }

  return (
        <BrowserRouter>
            <TicketsContextProvider>
              <Switch>
                <Route exact path="/login" component={LoginNew} /> 
                <Route exact path="/signup" component={Signup} />           
                <Route exact path="/forgetpsw" component={ForgetPassword} />   {/* Reset Password */}
                {/* <Route exact path="/create-company" component={Companies} /> */}
                <WhatsAppsProvider>
                  <LoggedInLayout>
                    <Route exact path="/ascview" component={AscviewHome} isPrivate />

                    <Route exact path="/" component={Home} isPrivate />

                    <ProtectedRoutesAscChat 
                      exact 
                      path="/dashboard" 
                      redirectTo="/" 
                      component={NewDashboard} 
                      isPrivate 
                    />

                    <ProtectedRoutesAscChat
                      exact
                      path="/tickets/:ticketId?"
                      redirectTo="/" 
                      component={TicketResponsiveContainer}
                      isPrivate
                    />

                    <ProtectedRoutesAscChat 
                      exact
                      path="/kanban"
                      redirectTo="/" 
                      component={Kanban}
                      isPrivate
                    />

                    <ProtectedRoutesAscChat
                      exact
                      path="/quick-messages"
                      redirectTo="/" 
                      component={QuickMessages}
                      isPrivate
                    />
                    <ProtectedRoutesAscChat
                      exact
                      path="/todolist"
                      redirectTo="/" 
                      component={ToDoList}
                      isPrivate
                    />

                    <ProtectedRoutesAscChat 
                      exact 
                      path="/contacts" 
                      redirectTo="/"  
                      component={Contacts} 
                      isPrivate 
                    />

                    <ProtectedRoutesAscChat
                      exact
                      path="/schedules"
                      redirectTo="/" 
                      component={Schedules}
                      isPrivate
                    />
                    
                    <Route exact path="/tags" component={Tags} isPrivate />
                    <Route exact path="/Email" component={Email} isPrivate />   {/* Email */}
                    <Route exact path="/EmailLis" component={EmailLis} isPrivate /> {/* Email */}
                    <Route exact path="/helps" component={Helps} isPrivate />
                    <Route exact path="/users" component={Users} isPrivate />
                    <Route exact path="/files" component={Files} isPrivate />
                    <Route exact path="/prompts" component={Prompts} isPrivate />
                    <Route exact path="/queue-integration" component={QueueIntegration} isPrivate />
                    <Route exact path="/departments" component={DepartmentDetails} isPrivate />
                    <Route exact path="/departmentsTableTest" component={Departments} isPrivate />
                    <Route exact path="/departments/:departmentId" component={DepartmentDetails} isPrivate />
                    <Route exact path="/departments/:departmentId/groups/:workspaceId/reports/:reportId" component={DepartmentReport} isPrivate />
                    <Route exact path="/departments-settings" component={DepartmentSettings} isPrivate />
                    <Route exact path="/departments-users" component={DepartamentsUsers} isPrivate />

                    <Route
                      exact
                      path="/connections"
                      component={Connections}
                      isPrivate
                    />

                    <Route
                      exact
                      path="/messages-api"
                      component={MessagesAPI}
                      isPrivate
                    />
                    <Route
                      exact
                      path="/settings"
                      component={SettingsCustom}
                      isPrivate
                    />
                    <Route
                      exact
                      path="/financeiro"
                      component={Financeiro}
                      isPrivate
                    />
                    <Route exact path="/queues" component={Queues} isPrivate />
                    <Route
                      exact
                      path="/announcements"
                      component={Annoucements}
                      isPrivate
                    />
                    <Route
                      exact
                      path="/subscription"
                      component={Subscription}
                      isPrivate
                    />
                    <Route exact path="/chats/:id?" component={Chat} isPrivate />
                    {showCampaigns && (
                      <>
                        <Route
                          exact
                          path="/contact-lists"
                          component={ContactLists}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/contact-lists/:contactListId/contacts"
                          component={ContactListItems}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/campaigns"
                          component={Campaigns}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/campaign/:campaignId/report"
                          component={CampaignReport}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/campaigns-config"
                          component={CampaignsConfig}
                          isPrivate
                        />
                      </>
                    )}
                  </LoggedInLayout>
                </WhatsAppsProvider>
              </Switch>
              <ToastContainer autoClose={3000} />
            </TicketsContextProvider>
        </BrowserRouter>
  );
};

export default Routes;
