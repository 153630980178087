import React, { useContext, useEffect } from 'react';
import HeaderDepartment from '../../components/HeaderDepartment';
import TableDepartments from '../../components/TableDepartments';
import FolderView from '../../components/FolderView';
import { AuthContext } from '../../context/Auth/AuthContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

  
const Departments = () => {

    const { user } = useContext(AuthContext);
    const history = useHistory();
    

    useEffect(() => {
        console.log(user, "user ascView")
        if (user && (!user.super && user.profile !== "admin")) {
        
        if ((user.ascview_usuario && user.ascview_usuario.profile !== "admin" && user.ascview_usuario.ativo !== 'Y')) {
            toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
            setTimeout(() => {
            history.push(`/departments`); // Redirecionar para a página de tickets ou outra página adequada
            }, 1000);
        }
        }
        
    }, [user, history]);

    return (
        <>
            <HeaderDepartment />
            <TableDepartments />
            <FolderView parentId={null} />
        </>
    )
}

export default Departments;