import api, { openApi } from "../../services/api";

const usePlans = () => {

    // const getPlanList = async (params) => {
    //     const { data } = await openApi.request({
    //         url: '/plans/list',
    //         method: 'GET',
    //         params
    //     });
    //     return data;
    // }

    const getPlanList = async (params) => {
        try {
            const { data } = await openApi.request({
                url: '/plans/list',
                method: 'GET',
                params
            });
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    // const list = async (params) => {
    //     const { data } = await api.request({
    //         url: '/plans/all',
    //         method: 'GET',
    //         params
    //     });
    //     return data;
    // }

    const list = async (params) => {
        try{
            const { data } = await api.request({
                url: '/plans/all',
                method: 'GET',
                params
            });
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    // const finder = async (id) => {
    //     const { data } = await api.request({
    //         url: `/plans/${id}`,
    //         method: 'GET'
    //     });
    //     return data;
    // }

    const finder = async (id) => {
        try{
            const { data } = await api.request({
                url: `/plans/${id}`,
                method: 'GET'
            });
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    // const save = async (data) => {
    //     const { data: responseData } = await api.request({
    //         url: '/plans',
    //         method: 'POST',
    //         data
    //     });
    //     return responseData;
    // }

    const save = async (data) => {
        try{
            const { data: responseData } = await api.request({
                url: '/plans',
                method: 'POST',
                data
            });
            return responseData;
        } catch (error) {
            console.log(error);
        }
    }

    // const update = async (data) => {
    //     const { data: responseData } = await api.request({
    //         url: `/plans/${data.id}`,
    //         method: 'PUT',
    //         data
    //     });
    //     return responseData;
    // }

    const update = async (data) => {
        try{
            const { data: responseData } = await api.request({
                url: `/plans/${data.id}`,
                method: 'PUT',
                data
            });
            return responseData;
        } catch (error) {
            console.log(error);
        }
    }

    // const remove = async (id) => {
    //     const { data } = await api.request({
    //         url: `/plans/${id}`,
    //         method: 'DELETE'
    //     });
    //     return data;
    // }

    const remove = async (id) => {
        try{
            const { data } = await api.request({
                url: `/plans/${id}`,
                method: 'DELETE'
            });
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    // const getPlanCompany = async (params, id) => {
    //     const { data } = await api.request({
    //         url: `/companies/listPlan/${id}`,
    //         method: 'GET',
    //         params
    //     });
    //     return data;
    // }

    const getPlanCompany = async (params, id) => {
        try {
            const { data } = await api.request({
                url: `/companies/listPlan/${id}`,
                method: 'GET',
                params,
            });
            return data;
        } catch (error) {
            console.log(error);
        }
    };
    

    return {
        getPlanList,
        list,
        save,
        update,
        finder,
        remove,
        getPlanCompany
    }
}

export default usePlans;