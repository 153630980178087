import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext'; // Certifique-se de importar corretamente seu AuthContext
import './styleNewIndex.css';
import logo from "../../assets/logo.png";
import { Button, makeStyles, Typography } from '@material-ui/core';
import { i18n } from "../../translate/i18n";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

export function LoginNew() {

  const [user, setUser] = useState({ email: "", password: "" });

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleLogin(user);
  };

  const useStyles = makeStyles(theme => ({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonText: {
      color: '#FFF'
    },
    removeUderline:{
      textDecoration: 'none'
    }
  }));

  const classes = useStyles();

  return (
    <div className="login-background">
      <div className="login-container">
        <div className="login-card">
          <img style={{ margin: "0 auto", width: "70%" }} src={logo} alt="Logologin" />
          <p>Acesse sua conta</p>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={user.email}
                onChange={handleChangeInput}
                placeholder="Digite seu email"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Senha</label>
              <input
                type="password"
                id="password"
                name="password"
                value={user.password}
                onChange={handleChangeInput}
                placeholder="Digite sua senha"
                required
              />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              <Typography className={classes.buttonText}>
                {i18n.t("login.buttons.submit")}
              </Typography>
            </Button>
          </form>
          <div className="login-footer">
            <a href="/recuperar-senha">
              <Typography color='primary' >
                  <Link
                href="#"
                variant="body2"
                component={RouterLink}
                to="/signup"
                >
                {i18n.t("login.buttons.register")}
                </Link>
              </Typography>
            </a>
          </div>
        </div>
      </div>
      <div className="welcome-section">
        <div className="welcome-message">
          <h1>Bem-vindo ao Asc Conecta</h1>
          <p>Tecnologia que acelera o futuro do seu negócio.</p>
        </div>
      </div>
    </div>
  );
}