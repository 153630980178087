import { Grid, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const NewChart = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const atendimentos = [
    5, 7, 12, 8, 15, 10, 18, 20, 22, 19, 25, 30, 
    27, 35, 40, 38, 45, 50, 55, 60, 65, 70, 55, 50
  ];
  const totalAtendimentos = atendimentos.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const [chartOptions, setChartOptions] = useState({
    series: [{
      name: "Atendimentos",
      data: [5, 7, 12, 8, 15, 10, 18, 20, 22, 19, 25, 30, 27, 35, 40, 38, 45, 50, 55, 60, 65, 70, 55, 50]
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        width: '100%'
      },
      colors: ['#3CA4AC'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        colors: [isDarkMode ? theme.palette.text.secondary : '#3CA4AC']
      },
      title: {
        text: `Atendimentos Hoje: ${totalAtendimentos}`,
        align: 'left',
        style: {
          color: isDarkMode ? theme.palette.text.secondary : '#3CA4AC',
          fontSize: '24px',
          fontWeight: 'bold'
        }
      },
      grid: {
        row: {
          colors: [isDarkMode ? '#444' : '#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [
          '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00',
          '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
          '20:00', '21:00', '22:00', '23:00'
        ],
        labels: {
          style: {
            colors: isDarkMode ? theme.palette.text.secondary : '#333',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: isDarkMode ? theme.palette.text.secondary : '#333',
          },
        },
      },
    }
  });

  useEffect(() => {
    setChartOptions(prevOptions => ({
      ...prevOptions,
      options: {
        ...prevOptions.options,
        stroke: {
          colors: [isDarkMode ? theme.palette.text.secondary : '#3CA4AC']
        },
        title: {
          ...prevOptions.options.title,
          style: {
            color: isDarkMode ? theme.palette.text.secondary : '#3CA4AC'
          }
        },
        grid: {
          row: {
            colors: [isDarkMode ? '#444' : '#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          labels: {
            style: {
              colors: isDarkMode ? theme.palette.text.secondary : '#333',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: isDarkMode ? theme.palette.text.secondary : '#333',
            },
          },
        },
      }
    }));
  }, [isDarkMode, theme.palette.text.secondary]);

  return (
    <Grid container justifyContent="center" style={{ height: '100%', padding: 20 }}>
      <Grid item xs={12}>
        <div id="chart" style={{ width: '100%', height: 'auto' }}>
          <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="line" height={350} />
        </div>
      </Grid>
    </Grid>
  );
};

export default NewChart;