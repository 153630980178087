import React, { useContext } from "react";
import { Route as RouterRoute, Redirect, useLocation } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
	const { isAuth, loading } = useContext(AuthContext);
	const location = useLocation();  

	if (!isAuth && isPrivate) {
		if (location.pathname === "/signup") {
			console.log('Página signup')
			return (
				<>
					{loading && <BackdropLoading />}
					<RouterRoute {...rest} component={Component} />
				</>
			);
		}

		return (
			<>
				{loading && <BackdropLoading />}
				<Redirect to={{ pathname: "/login", state: { from: location } }} />
			</>
		);
	}

	if (isAuth && !isPrivate) {

				if (location.pathname === "/signup") {
					console.log('Página signup')
					return (
						<>
							{loading && <BackdropLoading />}
							<RouterRoute {...rest} component={Component} />
						</>
					);
				}

		return (
			<>
				{loading && <BackdropLoading />}
				<Redirect to={{ pathname: "/", state: { from: location } }} />
			</>
		);
	}

	return (
		<>
			{loading && <BackdropLoading />}
			<RouterRoute {...rest} component={Component} />
		</>
	);
};

export default Route;
